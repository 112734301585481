<template>
  <v-btn
    v-if="!btnObj.hide && btnObj.destination && !isSending"
    depressed
    :outlined="btnObj.outlined"
    :color="btnObj.color"
    :to="{ name: btnObj.destination }"
    :disabled="btnObj.disabled"
    class="ma-1"
  >
    <v-icon v-if="btnObj.icon" left> $plus-circle</v-icon>
    <span :class="btnObj.textColor">{{ $trans(btnObj.text) }}</span>
  </v-btn>
  <v-btn
    v-else-if="!btnObj.hide && !btnObj.destination && !isSending"
    depressed
    :outlined="btnObj.outlined"
    :color="btnObj.color"
    :disabled="btnObj.disabled"
    class="ma-1"
    @click="btnObj.action()"
  >
    <v-icon v-if="btnObj.icon" left> $plus-circle</v-icon>
    <span :class="btnObj.textColor">{{ $trans(btnObj.text) }}</span>
  </v-btn>
</template>

<script>
import userActions from "@/calendesk/mixins/userActions";
import statsActions from "@/calendesk/mixins/statsActions";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "AppBarBtn",
  mixins: [userActions, statsActions, planActions],
  props: {
    selectButton: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    btnObj() {
      const route = this.$route.name;

      const list = [
        {
          route: "users",
          destination: "add_user",
          text: "add_client",
          hide: !this.usersExist,
        },
        {
          route: "employees",
          destination: "add_employee",
          text: "add_employee",
          hide: !this.employeesExist,
        },
      ];

      const defaultObj = {
        route: "dashboard",
        destination: "dashboard",
        text: "dashboard",
        icon: "$plus-circle",
        outlined: true,
        color: "secondary",
        textColor: "",
        disabled: false,
      };

      const foundRoute =
        list.filter((x) => {
          if (x.tab) {
            return x.route === route && x.tab === this.$route.params.tab;
          }
          return x.route === route;
        }) || defaultObj;

      return {
        ...defaultObj,
        ...foundRoute[this.selectButton],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-bottom: 0.5rem;
}

@media (min-width: 1264px) {
  .v-btn {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
